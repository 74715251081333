<script>
import Layout from "@/router/layouts/courses";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    },
    es: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      courses: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },
    };
  },
  methods: {
    getCourses() {
      this.courses.loading = true
      this.courses.list = null

      api
        .get('courses')
        .then(response => {
          if (response.data.status == 'success') {
            this.courses.list = response.data.list
            this.courses.loading = false
          } else {
            this.courses.list = null
          }
        })
        .catch(error => {
          this.courses.errored = error
          this.courses.loading = false
        })
        .finally(() => {
          this.courses.loading = false
        })
    }
  },
  mounted() {
    this.getCourses()
  },
  computed: {
    monitorWidth: function () {
      var width = screen.width;
      return width;
    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Teams University') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="courses.loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
    </div>
    <div v-else-if="!courses.list || courses.list && courses.list.length == 0">
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br>
          ainda não temos nenhum curso disponível
        </div>
      </div>
    </div>
    <div v-else class="row course-list">
      <div class="col-md-6 col-xl-4" v-for="(course, id) in courses.list" :key="id">
        <router-link tag="a" :to="'/courses/' + course.slug" class="course-row w-100 text-dark">
          <div class="mb-5">
            <img class="course-cover" :src="course.cover" />
            <h6 class="course-title m-0 text-white">{{ course.title }}</h6>
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.course-cover {
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 10 / 5;
}
</style>